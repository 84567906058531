/* user.css */

.user-list-container {
	max-width: 800px;
	margin: auto;
	padding: 20px;
}

button {
	margin-bottom: 10px;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 10px;
}

th,
td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

th {
	background-color: #f2f2f2;
}

#map-container {
	height: 400px;
	width: 100%; /* Adjust the width as needed */
	margin-top: 20px; /* Add margin for separation from the table */
}

/* Style for Leaflet map container */
.leaflet-container {
	height: 100%; /* Adjust the height to fill the container */
	width: 100%; /* Adjust the width to fill the container */
	margin: 0 auto;
}
