.login-container {
	background-image: url('../assets/bg6.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.login-box {
	background-color: rgba(1, 1, 1, 0.8);
	border-radius: 40px;
	padding: 5%;
	text-align: center;
	max-width: 600px;
	margin: auto;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-title {
	font-size: 50px;
	margin-bottom: 30px;
	color: #5ba4e8;
}

.input-group {
	margin-bottom: 15px;
	color: #5ba4e8;
	text-align: left;
	width: 290px;
	position: relative;
	margin-right: 25px;
}

.input-label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.icon-input {
	position: relative;
}

.input-icon {
	position: absolute;
	top: 50%;
	left: 8px;
	transform: translateY(-50%);
	color: black;
}

.input-group input {
	width: calc(100% - 24px);
	padding: 8px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 40%);
	color: #ffff;
	padding-left: 24px;
	padding-right: 24px;
}

.eye-icon {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	color: black;
	cursor: pointer;
}

.button-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-right: 15px;
}

.login-button,
.register-button {
	background-color: #5ba4e8;
	color: #fff;
	width: 120px;
	height: 40px;
	cursor: pointer;
	border-radius: 10px;
	margin: 10px auto;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.login-button {
	margin-left: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.register-button {
	margin-left: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.login-button:hover,
.register-button:hover {
	background-color: #4488d6;
}

.error-popup {
	animation: fadeOut 2s;
	padding: 10px;
	border-radius: 5px;
	background-color: red;
	color: #fff;
	margin-top: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.remember-me {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 15px;
	color: #fff;
}

.remember-me input[type='checkbox'] {
	margin-right: 10px;
}

.remember-me label {
	font-size: 14px;
}

@media (max-width: 767px) {
	.button-group {
		flex-direction: row;
		margin-top: 15px;
		text-align: center;
	}

	.login-box {
		width: 80%;
	}

	.login-title {
		font-size: 36px;
	}

	.button-group {
		flex-direction: column;
		margin-top: 15px;
		text-align: center;
	}

	.login-button,
	.register-button {
		margin: 8px 8px 10px 20px;
	}

	.register-button {
		margin-top: 10px;
	}
}

.success-popup,
.error-popup,
.welcomeAdminPopup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
	z-index: 1000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* CSS for Success Popup */
.success-popup {
	color: green;
}

/* CSS for Error Popup */
.error-popup {
	color: red;
}

/* CSS for Welcome Admin Popup */
.welcomeAdminPopup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
	z-index: 1000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	color: blue; /* Warna teks untuk popup "Welcome Admin" */
}

@media (min-width: 768px) and (max-width: 1023px) {
	.login-box {
		width: 90%;
	}
}

@media (min-width: 1024px) {
	.login-box {
		width: 70%;
	}
}
