.dashboard-container {
	position: relative;
	background-image: url('../assets/bg4.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.dashboard-username-container {
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	max-width: 80%;
	width: 100%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.popup-content {
	text-align: center;
}

.popup-close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 20px;
	color: #555;
}

.popup h2 {
	margin-bottom: 10px;
}

.popup p {
	margin-bottom: 20px;
}

.popup button {
	background-color: #5ba4e8;
	color: rgba(27, 79, 117, 0.75);
	padding: 10px 15px;
	font-size: 16px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.popup button:hover {
	background-color: #0a59a3;
}
.dashboard-username-btn {
	background-color: rgba(5, 87, 163, 0.6);
	border: none;
	color: #fff;
	font-size: 18px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 10px;
	border-radius: 5px;
}

body .dashboard-container .dashboard-box {
	margin-left: auto;
	margin-right: auto;
}

.dashboard-username-btn:focus {
	outline: none;
}

.dashboard-username-btn:hover {
	background-color: #407bbf;
}

.dropdown-icon {
	font-size: 16px;
	margin-left: 5px;
	color: #fff;
}

.dashboard-leaderboard-dropdown {
	position: absolute;
	top: 35px;
	left: 0;
	background-color: rgba(5, 87, 163, 0.6);
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.dashboard-leaderboard-dropdown ul {
	list-style: none;
	padding: 0;
	margin: 0;
	color: black;
}

.dashboard-leaderboard-dropdown ul li {
	cursor: pointer;
	margin-bottom: 5px;
}

.dashboard-leaderboard-dropdown:hover {
	background-color: #407bbf;
}

.dashboard-box {
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 40px;
	text-align: center;
	max-width: 30%;
	width: 80%;
	padding: 5%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.dashboard-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.dashboard-input-group1,
.dashboard-input-group2,
.dashboard-input-group3 {
	margin-bottom: 15px;
	color: #5ba4e8;
	text-align: left;
	width: 280px;
	position: relative;
	margin-left: 30px;
}

.dashboard-input-group1 label,
.dashboard-input-group2 label,
.dashboard-input-group3 label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}
.dashboard-leaderboard-dropdown ul li {
	cursor: pointer;
	margin-bottom: 5px;
}

.dashboard-leaderboard-dropdown span {
	cursor: pointer;
	color: #fff;
}

.dashboard-input-group1 select,
.dashboard-input-group2 select,
.dashboard-input-group3 select {
	width: calc(100% - 24px);
	padding: 8px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 40%);
	color: black;
}
.dashboard-button-group1 button {
	background-color: #5ba4e8; /* Warna hijau untuk tombol */
	color: white; /* Warna teks putih */
	padding: 10px 15px; /* Padding pada tombol */
	font-size: 16px; /* Ukuran teks */
	border: none; /* Hapus border */
	border-radius: 5px; /* Agar tombol terlihat lebih cantik */
	cursor: pointer; /* Tambahkan efek kursor */
}

.dashboard-button-group1 button:hover {
	background-color: #0a59a3; /* Warna tombol saat dihover */
}

.dashboard-button-group1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25px;
}

.play-stop-icon-wrapper {
	background-color: #000;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 2px solid #5ba4e8;
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.play-stop-icon {
	font-size: 24px;
	color: #5ba4e8;
}

.playing {
	border-color: red;
}

.stopped {
	border-color: green;
}

.leaderboard-button {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(5, 87, 163, 0.6);
	border: none;
	color: #fff;
	font-size: 18px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 10px;
	border-radius: 5px;
	text-decoration: none;
	transition: transform 0.3s ease;
	transform: translateX(-100%);
}

.dashboard-container:hover .leaderboard-button {
	transform: translateX(0);
}

.trophy-icon {
	font-size: 24px;
}

.leaderboard-text {
	margin-left: 10px;
}

.leaderboard-button:hover::before {
	content: 'Leaderboard';
	position: absolute;
	top: 0;
	left: 100%;
	width: 130px;
	height: 100%;
	background-color: rgba(5, 87, 163, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	transition: left 0.3s ease-in-out;
	border-radius: 10px;
}

.leaderboard-container:hover .leaderboard-button:hover::before {
	left: 0;
}

.dashboard-leaderboard-dropdown {
	position: absolute;
	top: 35px;
	left: 0;
	background-color: #5ba4e8;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.play-stop-icon-wrapper {
	background-color: #000;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 2px solid #5ba4e8;
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.play-stop-icon {
	font-size: 24px;
	color: #5ba4e8;
}

.playing {
	border-color: red;
}

.stopped {
	border-color: green;
}

@media (max-width: 767px) {
	.popup {
		max-width: 70%;
	}

	.dashboard-box {
		width: 100%;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	.dashboard-input-group1,
	.dashboard-input-group2,
	.dashboard-input-group3 {
		width: 100%;
		margin-right: 0;
		position: static;
	}
	.dashboard-container {
		background-size: cover;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.dashboard-box {
		width: 90%;
		margin-left: 5%;
	}
}

@media (min-width: 1024px) {
	.dashboard-box {
		width: 70%;
		margin-left: 15%;
	}
}
