.register-container {
	background-image: url('../assets/BG5.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.register-box {
	background-color: rgba(1, 1, 1, 0.8);
	border-radius: 40px;
	padding: 5%;
	text-align: center;
	max-width: 600px;
	margin: auto;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.register-title {
	font-size: 50px;
	margin-bottom: 20px;
	color: #5ba4e8;
}

.input-group1,
.input-group2,
.input-group3 {
	margin-bottom: 15px;
	color: #5ba4e8;
	text-align: left;
	width: 280px;
	position: relative;
	margin-right: 25px;
}

.input-group1 label,
.input-group2 label,
.input-group3 label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.input-group1 input,
.input-group2 input,
.input-group3 input {
	width: calc(100% - 24px);
	padding: 8px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 40%);
	color: #ffff;
	padding-left: 24px;
	padding-right: 24px;
}

.error-message {
	color: red;
	font-size: 14px;
	margin-top: 5px;
}

.button-group1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25px;
}

.create-account-button {
	background-color: #5ba4e8;
	color: #fff;
	width: 200px;
	height: 40px;
	cursor: pointer;
	border-radius: 10px;
	margin-bottom: 10px;
}

.login-link {
	color: #5ba4e8;
	font-size: 14px;
	margin: 0;
	cursor: pointer;
}

.login-link-text {
	font-weight: bold;
	cursor: pointer;
}

.google-signup {
	margin-top: 15px;
}

.google-signup-button {
	background-color: rgba(1, 1, 1, 0.1);
	color: #7c838a;
	width: 136px;
	height: 55px;
	cursor: pointer;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #7c838a;
	margin-right: 157px;
	text-align: left;
}

.google-icon {
	margin-right: 10px;
	width: 50px;
	height: 50px;
}

.success-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(1, 1, 1, 0.8);
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	text-align: center;
	z-index: 1000;
}

.success-popup p {
	font-size: 18px;
	color: #5ba4e8;
	margin-bottom: 10px;
}

.success-popup button {
	background-color: #5ba4e8;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	margin-top: 10px;
}

@media (max-width: 767px) {
	.register-box {
		width: 80%;
	}

	.register-title {
		font-size: 36px;
	}

	.button-group1 {
		flex-direction: column;
	}

	.login-link-text {
		font-weight: bold;
		cursor: pointer;
		margin-top: 10px;
	}

	.create-account-button {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.google-signup {
		margin-top: 15px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.register-box {
		width: 90%;
	}
}

@media (min-width: 1024px) {
	.register-box {
		width: 70%;
	}
}
